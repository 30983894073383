import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import './index.scss';

import { ethers } from "ethers";
import Web3Modal from "web3modal";
import GoerliJson from "../../utils/abi/goerli.json";
import WalletConnectProvider from "@walletconnect/web3-provider";
import whitelist from '../../utils/whitelist.json';
import MerkleTree from 'merkletreejs';
import keccak256 from 'keccak256';
import {isPhone} from '../../utils/util.js';

import Music from '../../components/music/music.js';
import { withTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay } from 'swiper';
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper";
import { message, Popover as Popover_ant } from 'antd';
import { useTranslation } from 'react-i18next';

import banner from '../../assets/images/index/banner.png';
import banner3 from '../../assets/images/index/banner3-1.jpg';
import hodler from '../../assets/images/index/hodler.png';
// import bg_team3 from '../../assets/images/index/bg-team3.png';
// import bg_team2 from '../../assets/images/index/bg-team2.png';
// import bg_team4 from '../../assets/images/index/bg-team4.png';
// import bottom from '../../assets/images/index/bottom.png';
import hodler_beast from '../../assets/images/index/hodler-beast.png';
import loading from '../../assets/images/index/loading.png';
// import beta from "../../assets/images/team/beta.png";
// import daqiang from "../../assets/images/team/daqiang.png";
// import jasonsmith from "../../assets/images/team/jasonsmith.png";
// import lo from "../../assets/images/team/lo.png";
// import richardson from "../../assets/images/team/richardson.png";
import logo from '../../assets/images/header/logo.png';

import { setAccount, setSigner } from '../../store/store'

const PUBLIC_CHAIN_ID = "5";
const PUBLIC_CHAIN_MAP = {
  "1": {
    "network": "mainnet",
    "etherscan": "etherscan.io"
  },
  "3": {
    "network": "ropsten",
    "etherscan": "ropsten.etherscan.io"
  },
  "5": {
    "network": "goerli",
    "etherscan": "goerli.etherscan.io"
  }
}

// const providerOptions = {
//   walletconnect: {
//     package: WalletConnectProvider,
//     options: {
//       infuraId: 'e2ab178dd7c64af0849505059466ba2b',
//     },
//   },
// };
// let web3ModelInstance;
// if (typeof window !== "undefined") {
//   web3ModelInstance = new Web3Modal({
//     network: PUBLIC_CHAIN_MAP[PUBLIC_CHAIN_ID].network,
//     cacheProvider: true,
//     providerOptions,
//   });
// }


function Index(props) {
  var langmap = require("langmap");
  const { t, i18n } = useTranslation();
  const { user } = useSelector(state => state)
  const dispatch = useDispatch()

  const [mintStatus, setMintStatus] = useState(0); //before-0  mint-1 after-2
  const [totalSupply, setTotalSupply] = useState(0);
  const [collectionSize, setCollectionSize] = useState(0);
  const [numberMinted, setNumberMinted] = useState(0);
  const [maxAmount, setMaxAmount] = useState(3);
  const [mintNumber, setMintNumber] = useState(1);

  const [showLoading, setShowLoading] = useState(false);
  const [expanded, setExpanded] = useState(false);
  //swiper
  SwiperCore.use([Autoplay]);

  useEffect(() => {
    if(user.account){
      initData();
    }
    let left = 0;
    let ul = document.getElementById("ul_img");
    ul.innerHTML += ul.innerHTML;
    let lis = ul.getElementsByTagName("img");
    ul.style.width = (lis[0].offsetWidth * lis.length) + "px";
    function scroll() {
      left -= 1;
      if (left <= -(Math.min(document.body.clientWidth, 1440) * 435 / 1920)) left = 0;
      ul.style.left = left + "px";
      requestAnimationFrame(scroll)
    }
    requestAnimationFrame(scroll)

  }, [user.account]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  async function initData() {
    const contractWithSigner = user.contractWithSigner
    const _account = user.account
    //whiteListStartTime
    const whiteListStartTime = (await contractWithSigner.whiteListStartTime()).toString();
    const publicStartTime = (await contractWithSigner.publicStartTime()).toString();
    const totalSupply = parseInt((await contractWithSigner.totalSupply()).toString());
    const collectionSize = parseInt((await contractWithSigner.collectionSize()).toString());
    const _mintStatus = new Date().getTime() < whiteListStartTime.toString() * 1000 ? 0 : (totalSupply >= collectionSize ? 2 : 1);
    setTotalSupply(totalSupply);
    setCollectionSize(collectionSize);
    setMintStatus(_mintStatus);

    if (_account) {
      const _numberMinted = (await contractWithSigner.numberMinted(_account)).toString();
      setNumberMinted(_numberMinted);
      const _perAddressMaxMintAmount = (await contractWithSigner.perAddressMaxMintAmount()).toString();

      if (_mintStatus == 1) {
        let whitelistFreeCount = String(whitelist.list[_account] || 0); //whitelist config
        let _maxAmount = 1

        if (new Date().getTime() < publicStartTime.toString() * 1000) {  //whiteListTime
          _maxAmount = Math.min(whitelistFreeCount, _perAddressMaxMintAmount) - _numberMinted;
        } else {  //publicTime
          _maxAmount = _perAddressMaxMintAmount - _numberMinted;
        }
        setMaxAmount(_maxAmount);
        if (_maxAmount <= 0) setMintNumber(0);
      }
    }
  }

  const connectWallet = async () => {
    try {
      const _instance = await props.web3ModelInstance.connect();
      const _provider = new ethers.providers.Web3Provider(_instance);
      const _signer = _provider.getSigner();
      const _contract = new ethers.Contract(
        '0x0ae8E5D4e731E94B2e00470294106A7Ee7C02400',
        GoerliJson.abi,
        _provider
      );

      const contractWithSigner = _contract.connect(_signer);
      const _account = await _signer.getAddress()
      dispatch(setSigner(contractWithSigner))
      dispatch(setAccount(_account))
      

      //initData
      await initData();
    } catch (error) {
      console.log(error);
      // setError(error);
    }
  };

  async function mint() {
    if (mintNumber > 0) { }
    if (user.account) {
      setShowLoading(true);
      try {
        //merkle proof
        const leafNodes = Object.keys(whitelist.list).map((addr) => keccak256(addr));
        const tree = new MerkleTree(leafNodes, keccak256, { sortPairs: true });
        let leaf = keccak256(user.account);
        let proof = tree.getHexProof(leaf);
        console.log(`Proof of ${user.account}: `, proof);
        //count
        let count = mintNumber;//"1";
        const whitelistFreeCount = whitelist.list[user.account] || 0; //whitelist config
        //value
        let eth = "0";
        const contractWithSigner = user.contractWithSigner;
        const publicStartTime = (await contractWithSigner.publicStartTime()).toString();
        if (new Date().getTime() < publicStartTime.toString() * 1000) {  //whiteListTime
          eth = (await contractWithSigner.publicPrice()).toString();
        } else {  //publicTime
          eth = (await contractWithSigner.whiteListPrice()).toString();
        }
        const value = ethers.utils.parseEther(eth);
        //tx
        console.log(`count: ${count}, value: ${value}, proof: ${proof}, signer: ${contractWithSigner}`);
        const tx = await contractWithSigner.mint(count, whitelistFreeCount, proof, {
          value: (value),
        });

        const response = await tx.wait();
        message.success('Mint successed');
        const etherscanDomain = PUBLIC_CHAIN_MAP[PUBLIC_CHAIN_ID].etherscan;
        console.log(response);
        console.log(`etherscan link: https://${etherscanDomain}/tx/${response.transactionHash}`);
      } catch (e) {
        console.log(`error: ${e}`);
        message.error('Mint failed');
      }
    } else {
      connectWallet();
    }
    setShowLoading(false);
  }

  function changeMintNumber(type) {
    const _mintNumber = mintNumber || 0;
    if (type === "add") {
      if (_mintNumber < maxAmount) {
        setMintNumber(_mintNumber + 1);
      } else {
        message.info(`You can also mint up to ${maxAmount}`);
      }
    } else {
      if (_mintNumber > 0) {
        setMintNumber(_mintNumber - 1);
      } else {
        message.info(`The number of mint cannot be less than 0`);
      }
    }
  }

  const Banner = () => {
    if (mintStatus == 0) { //mintBefore
      return (
        <div className={"banner-content mint-before"}>
          <div className={"mint-date"}>{t('mint date', { value: "2022-08-15 00:00:00" })}</div>
          <div className={"mint-intro"}>
            {t('join members')}
            <br />
            {t('presale value', { value: "1000" })}
            <br />
            {t('presale rule')}
          </div>
          <div className={"mint-box"}>
            <div className={"mint-btn"}>Mint</div>
          </div>
        </div>
      )
    } else if (mintStatus == 1) { //mint
      return (
        <div className={"banner-content mint"}>
          <div className={"mint-info"}>
            <div className={"mint-info-item holders"}>{t('Number of holders')}：<span>{totalSupply}</span></div>
            <div className={"mint-info-item transaction-amount"}>{t('Transaction amount')}：<span>0E/100000E</span></div>
            <div className={"mint-info-item floor-price"}>{t('Floor price')}：<span>0E</span></div>
          </div>
          <div className={"mint-intro"}>
            {t('join members')}
            <br />
            {t('presale value', { value: "1000" })}
            <br />
            {t('presale rule')}
          </div>
          <div className={"mint-box"}>
            <div className={"mint-btn"} onClick={mint}>Mint <span>{totalSupply}/{collectionSize}</span></div>
            <div className={"mint-number"}>
              {t('Mint number')}：
              <div className={"mint-numbox"}>
                <div className="btn btn-reduce" onClick={() => changeMintNumber('reduce')}>-</div>
                <input className="numbox-input" value={mintNumber} disabled />
                <div className="btn btn-add" onClick={() => changeMintNumber('add')}>+</div>
              </div>
            </div>
          </div>
        </div>
      )
    } else if (mintStatus == 2) { //mintAfter
      return (
        <div className={"banner-content mint-after"}>
          <div className={"mint-info"}>
            <div className={"mint-info-item holders"}>{t('Number of holders')}：<span>{totalSupply}</span></div>
            <div className={"mint-info-item transaction-amount"}>{t('Transaction amount')}：<span>0E/100000E</span></div>
            <div className={"mint-info-item floor-price"}>{t('Floor price')}：<span>0E</span></div>
          </div>
          <img className={"hodler"} src={hodler} />
          <div className={"check-btn"}>Check it on Opensea</div>
        </div>
      )
    }
  }

  return (
    <div className={[ "page-box", isPhone() ? 'phone' : '' ].join(' ')}>
      <div className={"main"}>
        <div className={"banner"}>
          <img className={"banner-bg"} src={banner3} />
          <Banner />
        </div>
        <div id={"content"} className={"content"}>
          <div className={"line"} id={"line"}>
            <div className="scroll-area" id={"ul_img"}>
              <img src={hodler_beast} />
              <img src={hodler_beast} />
              <img src={hodler_beast} />
              <img src={hodler_beast} />
            </div>
          </div>
          <div className={"faq-box"}>
            <div id={"faq"} className={"faq"}>
              <div className={"faq-title"}>
                <div className={"text"}>{t('common problem')}</div>
              </div>
              <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                <AccordionSummary
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <div className={"page-number"}><span>01</span></div>
                  <div className={"arrow"}></div>
                  <Typography>{t('Who is Hodler Beast')}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="body2">{t('HB is an interesting social experiment')}</Typography>

                  <Typography variant="body2">{t('We want to achieve')}</Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                <AccordionSummary
                  aria-controls="panel2bh-content"
                  id="panel2bh-header"
                >
                  <div className={"page-number"}><span>02</span></div>
                  <div className={"arrow"}></div>
                  <Typography>{t('Why are we doing this project')}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="body2">
                    {t('The reason why we do HB is based on the following phenomena and facts observed in the NFT market')}
                  </Typography>
                  <Typography variant="body2">
                    {t("If you can't hold, you won't be rich")}
                  </Typography>
                  <Typography variant="body2">
                    {t('If you observe carefully, you will find that the vast majority of risks in this market are actually borne by NFT traders')}
                  </Typography>
                  <Typography variant="body2">
                    {t('Especially those staunch holders who have contributed a lot of time and energy to the project and the community and Ethereum')}
                    {t('HB try to change all of this and make Holder the biggest beneficiary.')}
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                <AccordionSummary
                  aria-controls="panel3bh-content"
                  id="panel3bh-header"
                >
                  <div className={"page-number"}><span>03</span></div>
                  <div className={"arrow"}></div>
                  <Typography>{t('How will we guarantee the realization of Longer Hold')}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="body2">
                    {t('higher.return.answer.one')}
                  </Typography>
                  <Typography variant="body2">
                    {t('higher.return.answer.two')}
                  </Typography>
                  <Typography variant="body2">
                    {t('higher.return.answer.three')}
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                <AccordionSummary
                  aria-controls="panel4bh-content"
                  id="panel4bh-header"
                >
                  <div className={"page-number"}><span>04</span></div>
                  <div className={"arrow"}></div>
                  <Typography>{t('total supply')}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="body2">
                    {t('total supply value', { value: "5,000" })}
                  </Typography>
                  <Typography variant="body2">
                    {t('royalty ratio', { value: "10%" })}
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                <AccordionSummary
                  aria-controls="panel5bh-content"
                  id="panel5bh-header"
                >
                  <div className={"page-number"}><span>05</span></div>
                  <div className={"arrow"}></div>
                  <Typography>{t('refund policy')}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="body2">
                    {t('refund policy desc')}
                  </Typography>

                  <Typography variant="body2">
                    {t('The loner is either a beast or a god')}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
        </div>
      </div>
      <div className={"footer"}>
        <img className={"logo"} src={logo} />
        Copyright © 2022-2023 Hodler Labs
        {/* <div class='media'>
          <a className={"media-item twitter"} href="https://twitter.com/DiamondHand_os" target="_blank"></a>
        </div> */}
      </div>
      <Music />
      <div className={["loading-box", showLoading ? "" : "hide"].join(' ')}>
        <img className={"loading"} src={loading} />
      </div>
    </div>
  );
}
export default withTranslation()(Index);
