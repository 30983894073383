import React, { useEffect, useState } from 'react';
import {Link, useLocation} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import { ethers } from "ethers";
import Web3Modal from "web3modal";
import GoerliJson from "../../utils/abi/goerli.json";
import WalletConnectProvider from "@walletconnect/web3-provider";
import './header.scss';

import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import { List, ListItemButton, Popover, Button } from '@mui/material';
import { withTranslation } from 'react-i18next';
import { useTranslation } from 'react-i18next';
import { message, Popover as Popover_ant } from 'antd';

import logo from '../../assets/images/header/logo.png';

import { setAccount, setSigner, setWeb3ModelInstance } from '../../store/store'

function Header(props) {
  const currentPath = useLocation();
  console.log(currentPath);
  // const history = useHistory();

  var langmap = require("langmap");
  const { t, i18n } = useTranslation();
  const [showMenu, setShowMenu] = useState(false);
  const { user } = useSelector(state => state)
	const dispatch = useDispatch()


  // const PUBLIC_CHAIN_ID = "5";
  // const PUBLIC_CHAIN_MAP = {
  //   "1": {
  //     "network": "mainnet",
  //     "etherscan": "etherscan.io"
  //   },
  //   "3": {
  //     "network": "ropsten",
  //     "etherscan": "ropsten.etherscan.io"
  //   },
  //   "5": {
  //     "network": "goerli",
  //     "etherscan": "goerli.etherscan.io"
  //   }
  // }

  // const providerOptions = {
  //   walletconnect: {
  //     package: WalletConnectProvider,
  //     options: {
  //       infuraId: 'e2ab178dd7c64af0849505059466ba2b',
  //     },
  //   },
  // };
  // let web3ModelInstance;
  // if (typeof window !== "undefined") {
  //   web3ModelInstance = new Web3Modal({
  //     network: PUBLIC_CHAIN_MAP[PUBLIC_CHAIN_ID].network,
  //     cacheProvider: true,
  //     providerOptions,
  //   });
  // }

  useEffect(() => {
    if (props.web3ModelInstance.cachedProvider) {
      connectWallet();
    }
  }, []);

  function jumpFaq() {
    const scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
    document.documentElement.scrollTop = scrollTop + document.getElementById('faq').getBoundingClientRect().top - (document.body.clientWidth > 1200 ? 80 : 60) + (Math.min(document.body.clientWidth, 1440) / 1920 * 100);
    setShowMenu(false);
  }

  function jumpTeam() {
    const scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
    document.documentElement.scrollTop = scrollTop + document.getElementById('team').getBoundingClientRect().top - (document.body.clientWidth > 1200 ? 80 : 60) + (Math.min(document.body.clientWidth, 1440) / 1920 * 650);
    setShowMenu(false);
  }

  const connectWallet = async () => {
    try {
      const _instance = await props.web3ModelInstance.connect();
      const _provider = new ethers.providers.Web3Provider(_instance);
      const _signer = _provider.getSigner();
      const _contract = new ethers.Contract(
        '0x0ae8E5D4e731E94B2e00470294106A7Ee7C02400',
        GoerliJson.abi,
        _provider
      );

      const contractWithSigner = _contract.connect(_signer);
      const _account = await _signer.getAddress()
      dispatch(setSigner(contractWithSigner))
      dispatch(setAccount(_account))
      
    } catch (error) {
      console.log(error);
      // setError(error);
    }
  };

  async function disconnect() {
    await props.web3ModelInstance.clearCachedProvider();
    dispatch(setSigner(null));
    dispatch(setAccount(''));
  }

  const content = (
    <div className={"user-pop"}>
      <p>{user.account}</p>
      <p className={"btn"} onClick={disconnect}>DISCONNECT</p>
    </div>
  );

  const Login = () => {
    if (document.body.clientWidth > 816) {
      if (user.account) {
        return (
          <Popover_ant placement="bottomLeft" content={content}>
            <div className={"menu-item connect connected"}>
            {user.account.length == 42 ? user.account.slice(0,4)+'...'+user.account.slice(-4) : user.account}
            </div>
          </Popover_ant>
        )
      } else {
        return (
          <div className={"menu-item connect"} onClick={connectWallet} title="Connect Wallet"></div>
        )
      }
    } else {
      if (user.account) {
        return (
          <div className={"menu-item media wallet"} title="DISCONNECT" onClick={disconnect}>{user.account}</div>
        )
      } else {
        return (
          <div className={"menu-item media wallet"} onClick={connectWallet} >CONNECT WALLET</div>
        )
      }
    }
  }

  if (document.body.clientWidth > 816) {
    return (
      <div className={"header-box"}>
        <div className={"header"}>
          <img className={"logo"} src={logo} />
          <div className={"menu"}>
            <div className={["menu-item",currentPath.pathname == '/' ? 'active' : ''].join(' ')} ><Link to="/">HOME</Link></div>
            <div className={["menu-item"].join(' ')} ><a href="http://diamondhands.homes" target="_blank" >DIAMOND HANDS</a></div>
            {/* <div className={"menu-item"} onClick={async () => { jumpFaq() }} >{t('common problem')}</div>
            <div className={"menu-item"} onClick={async () => { jumpTeam() }} >{t('about us')}</div> */}
            <div className={"menu-item lang-box"}>
              <PopupState variant="popover" popupId="demo-popup-popover">
                {(popupState) => (
                  <div>
                    <Button variant="text" {...bindTrigger(popupState)}>
                      {langmap[i18n.language]["nativeName"] == '中文' ? 'CN' : 'EN'}
                    </Button>
                    <Popover
                      {...bindPopover(popupState)}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                      className={'lang-selector'}
                    >
                      <List>
                        <ListItemButton selected={i18n.language=='zh'} onClick={async () => {
                          i18n.changeLanguage('zh');
                          popupState.close();
                        }}>
                          CN
                        </ListItemButton>
                        <ListItemButton selected={i18n.language=='en'} onClick={async () => {
                          i18n.changeLanguage('en');
                          popupState.close();
                        }}>
                          EN
                        </ListItemButton>
                      </List>
                    </Popover>
                  </div>
                )}
              </PopupState>
            </div>
            <a className={"menu-item media opensea"} href="https://opensea.io/zh-TW/collection/hodlerbeast" target="_blank"></a>
            <a className={"menu-item media twitter"} href="https://twitter.com/hodlerbeast" target="_blank"></a>
            <a className={"menu-item media discord"} href="https://discord.gg/gdvvVBmhm4" target="_blank"></a>
            <Login />
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div className={"phone-header-box"}>
        <div className={"header"}>
          <img className={"logo"} src={logo} />
          <div className={"header-right"}>
            <div className={["menu-item",currentPath.pathname == '/' ? 'active' : ''].join(' ')} ><Link to="/">{t('home')}</Link></div>
            <div className={["menu-item",currentPath.pathname == '/nft' ? 'active' : ''].join(' ')} ><Link to="/nft">NFT</Link></div>
            <div className={"menu-btn"} onClick={async () => { setShowMenu(!showMenu) }}></div>
          </div>
        </div>
        <div className={["menu-page", showMenu ? "" : "hide"].join(' ')} onClick={async (e) => { e.preventDefault(); setShowMenu(false); }}>
          {/* <div className={"menu-item"} onClick={async () => { jumpFaq() }} >{t('common problem')}</div>
          <div className={"menu-item"} onClick={async () => { jumpTeam() }} >{t('about us')}</div> */}
          {/* <div className={"menu-item lang-box"}>
                <PopupState variant="popover" popupId="demo-popup-popover">
                {(popupState) => (
                    <div>
                    <Button variant="text" {...bindTrigger(popupState)}>
                        { langmap[i18n.language]["nativeName"] }
                    </Button>
                    <Popover
                        {...bindPopover(popupState)}
                        anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                        }}
                        transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                        }}
                        className={'lang-selector'}
                    >
                        <List>
                        <ListItemButton onClick={ async() => { 
                            i18n.changeLanguage('zh'); 
                            popupState.close();
                        }}>
                            中文
                        </ListItemButton>
                        <ListItemButton onClick={ async() => { 
                            i18n.changeLanguage('en'); 
                            popupState.close();
                        }}>
                            ENGLISH
                        </ListItemButton>
                        </List>
                    </Popover>
                    </div>
                )}
                </PopupState> 
            </div> */}
          <a className={"menu-item media opensea"} href="https://opensea.io/zh-TW/collection/hodlerbeast" target="_blankw">OPENSEA</a>
          <a className={"menu-item media twitter"} href="https://twitter.com/hodlerbeast" target="_blank">TWITTER</a>
          <a className={"menu-item media discord"} href="https://discord.gg/gdvvVBmhm4" target="_blank">DISCORD</a>
          <Login />
        </div>
      </div>
    )
  }
}

export default Header;
