export function isPhone(){  
  var userAgentInfo = navigator.userAgent;
  var Agents = new Array("Android", "iPhone", "SymbianOS", "Windows Phone", "iPad", "iPod");  
  var flag = false;  
  for (var v = 0; v < Agents.length; v++) {  
      if (userAgentInfo.indexOf(Agents[v]) > 0) { flag = true; break; }  
  }  
  return flag;  
}

function trimTrailingZeroes(value) {
  return value.replace(/\.?0*$/, '');
}

function formatWithCommas(value) {
  const pattern = /(-?\d+)(\d{3})/;
  while (pattern.test(value)) {
      value = value.replace(pattern, '$1,$2');
  }
  return value;
}
export function formatAmount(balance, decimals=18, fracDigits=4) {
  const wholeStr = balance.substring(0, balance.length - decimals) || '0';
  const fractionStr = balance.substring(balance.length - decimals).padStart(decimals, '0').substring(0, fracDigits);
  return trimTrailingZeroes(`${formatWithCommas(wholeStr)}.${fractionStr}`);
}
