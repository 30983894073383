import { createSlice, configureStore } from '@reduxjs/toolkit'

const initialState = {
    account: "",
    contractWithSigner:null,
    web3ModelInstance:null,
}

const userReducer = createSlice({
    name: "user",
    initialState: initialState,
    reducers: {
        setAccount (state, action) {
            state.account= action.payload
        },
        setSigner (state, action) {
            state.contractWithSigner= action.payload
        },
        setWeb3ModelInstance (state, action) {
            state.web3ModelInstance= action.payload
        },
    }
})

const store = configureStore({
    reducer: {
        user: userReducer.reducer
    },
    devTools: true
})

export const { setAccount, setSigner, setWeb3ModelInstance } = userReducer.actions
export default store