import './App.css';
import './locals/i18n.js';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Index from './views/index/index';
// import DiamondHand from './views/diamondhand/diamondhand';
import Header from './components/header/header.js';
import { ethers } from "ethers";
import Web3Modal from "web3modal";
import GoerliJson from "./utils/abi/goerli.json";
import WalletConnectProvider from "@walletconnect/web3-provider";



const PUBLIC_CHAIN_ID = "5";
const PUBLIC_CHAIN_MAP = {
  "1": {
    "network": "mainnet",
    "etherscan": "etherscan.io"
  },
  "3": {
    "network": "ropsten",
    "etherscan": "ropsten.etherscan.io"
  },
  "5": {
    "network": "goerli",
    "etherscan": "goerli.etherscan.io"
  }
}

const providerOptions = {
  walletconnect: {
    package: WalletConnectProvider,
    options: {
      infuraId: 'e2ab178dd7c64af0849505059466ba2b',
    },
  },
};
let web3ModelInstance;
if (typeof window !== "undefined") {
  web3ModelInstance = new Web3Modal({
    network: PUBLIC_CHAIN_MAP[PUBLIC_CHAIN_ID].network,
    cacheProvider: true,
    providerOptions,
  });
}


function App() {
  return (
    <BrowserRouter>
    <div>  
      <Header web3ModelInstance={web3ModelInstance}/>
      <Routes>
        <Route path='/' element={<Index web3ModelInstance={web3ModelInstance} />} />
      </Routes>
    </div>
  </BrowserRouter>
  );
}

export default App;

