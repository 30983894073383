import React,{useEffect,useState} from 'react';
import './music.scss';
import icon from '../../assets/images/music/music-icon.png';
import playbtn from '../../assets/images/music/play.png';

function Music() {
    const list = [
        {
            name:'Shoot to Thrillmusic',
            intro:'DC',
            url:'./music/AC／DC - Shoot to Thrill.mp3'
        },
        {
            name:'Windows',
            intro:'AWOLNATION',
            url:'./music/AWOLNATION - Windows.mp3'
        },
        {
            name:'Chosen',
            intro:'Blood Orange',
            url:'./music/Blood Orange - Chosen.mp3'
        },
        {
            name:"Knockin' On Heaven's Door",
            intro:'Bob Dylan',
            url:"./music/Bob Dylan - Knockin' On Heaven's Door.mp3"
        },
        {
            name:'Like a Rolling Stone',
            intro:'Bob Dylan',
            url:'./music/Bob Dylan - Like a Rolling Stone.mp3'
        },
        {
            name:'By This River',
            intro:'Brian Eno',
            url:'./music/Brian Eno - By This River.mp3'
        },
        {
            name:'Hungry Heart',
            intro:'Bruce Springsteen',
            url:'./music/Bruce Springsteen - Hungry Heart.mp3'
        },
        {
            name:'Vitamin C',
            intro:'Can',
            url:'./music/Can - Vitamin C.mp3'
        },
        {
            name:'The Son of Flynn (From ＂TRON： Legacy＂／Score)',
            intro:'Daft Punk',
            url:'./music/Daft Punk - The Son of Flynn (From ＂TRON： Legacy＂／Score).mp3'
        },
        {
            name:'Loving the Alien (Live)',
            intro:'David Bowie',
            url:'./music/David Bowie - Loving the Alien (Live).mp3'
        },
        {
            name:'Moonage Daydream (2012 Remaster)',
            intro:'David Bowie',
            url:'./music/David Bowie - Moonage Daydream (2012 Remaster).mp3'
        },
        {
            name:'Still D.R.E',
            intro:'Dr. Dre,Snoop Dogg',
            url:'./music/Dr. Dre,Snoop Dogg - Still D.R.E.mp3'
        },
        {
            name:'Tomorrow',
            intro:'Electric Youth',
            url:'./music/Electric Youth - Tomorrow.mp3'
        },
        {
            name:'Without Me',
            intro:'Eminem',
            url:'./music/Eminem - Without Me.mp3'
        },
        {
            name:'Godzilla',
            intro:'Eminem,Juice WRLD',
            url:'./music/Eminem,Juice WRLD - Godzilla.mp3'
        },
        {
            name:'Outatime',
            intro:'FM-84',
            url:'./music/FM-84 - Outatime.mp3'
        },
        {
            name:'My Way',
            intro:'Frank Sinatra',
            url:'./music/Frank Sinatra - My Way.mp3'
        },
        {
            name:'Damn It Feel Good to Be a Gangsta',
            intro:'Geto Boys,Scarface',
            url:'./music/Geto Boys,Scarface - Damn It Feel Good to Be a Gangsta.mp3'
        },
        {
            name:'Raven',
            intro:'GoGo Penguin',
            url:'./music/GoGo Penguin - Raven.mp3'
        },
        {
            name:'Feel Good Inc',
            intro:'Gorillaz,De La Soul',
            url:'./music/Gorillaz,De La Soul - Feel Good Inc.mp3'
        },
        {
            name:"Sweet Child O' Mine",
            intro:"Guns N' Roses",
            url:"./music/Guns N' Roses - Sweet Child O' Mine.mp3"
        },
        {
            name:'Empire State Of Mind',
            intro:'Jay-Z,Alicia Keys',
            url:'./music/Jay-Z,Alicia Keys - Empire State Of Mind.mp3'
        },
        {
            name:'Time in a Bottle',
            intro:'Jim Croce',
            url:'./music/Jim Croce - Time in a Bottle.mp3'
        },
        {
            name:'We Find Ourselves',
            intro:'JODA',
            url:'./music/JODA - We Find Ourselves.mp3'
        },
        {
            name:'Stay Alive',
            intro:'José González',
            url:'./music/José González - Stay Alive.mp3'
        },
        {
            name:'Step Out',
            intro:'José González',
            url:'./music/José González - Step Out.mp3'
        },
        {
            name:'Atmosphere',
            intro:'Joy Division',
            url:'./music/Joy Division - Atmosphere.mp3'
        },
        {
            name:'Power',
            intro:'Kanye West',
            url:'./music/Kanye West - Power.mp3'
        },
        {
            name:'Water',
            intro:'Kanye West,Ant Clemons',
            url:'./music/Kanye West,Ant Clemons - Water.mp3'
        },
        {
            name:'Paranoid',
            intro:'Kanye West,Mr Hudson',
            url:'./music/Kanye West,Mr Hudson - Paranoid.mp3'
        },
        {
            name:'All The Stars',
            intro:'Kendrick Lamar,SZA',
            url:'./music/Kendrick Lamar,SZA - All The Stars.mp3'
        },
        {
            name:'I Talk To The Wind (2009 Stereo Mix)',
            intro:'King Crimson',
            url:'./music/King Crimson - I Talk To The Wind (2009 Stereo Mix).mp3'
        },
        {
            name:'Only the Brave',
            intro:'LeBrock',
            url:'./music/LeBrock - Only the Brave.mp3'
        },
        {
            name:'Suzanne',
            intro:'Leonard Cohen',
            url:'./music/Leonard Cohen - Suzanne.mp3'
        },
        {
            name:'You Want It Darker (Paul Kalkbrenner Remix)',
            intro:'Leonard Cohen,Paul Kalkbrenner',
            url:'./music/Leonard Cohen,Paul Kalkbrenner - You Want It Darker (Paul Kalkbrenner Remix).mp3'
        },
        {
            name:"Step Up ／ Nobody's Listening ／ It's Goin' Down (Live)",
            intro:'Linkin Park',
            url:"./music/Linkin Park - Step Up ／ Nobody's Listening ／ It's Goin' Down (Live).mp3"
        },
        {
            name:'Blue Monday',
            intro:'New Order',
            url:'./music/New Order - Blue Monday.mp3'
        },
        {
            name:'Champagne Supernova',
            intro:'Oasis',
            url:'./music/Oasis - Champagne Supernova.mp3'
        },
        {
            name:'Last Train Home',
            intro:'Pat Metheny Group',
            url:'./music/Pat Metheny Group - Last Train Home.mp3'
        },
        {
            name:'Helpless',
            intro:'Patti Smith',
            url:'./music/Patti Smith - Helpless.mp3'
        },
        {
            name:'Money',
            intro:'Pink Floyd',
            url:'./music/Pink Floyd - Money.mp3'
        },
        {
            name:'Time',
            intro:'Pink Floyd',
            url:'./music/Pink Floyd - Time.mp3'
        },
        {
            name:'Tunnel',
            intro:'Polo & Pan,Channel Tres',
            url:'./music/Polo & Pan,Channel Tres - Tunnel.mp3'
        },
        {
            name:'Let Down',
            intro:'Radiohead',
            url:'./music/Radiohead - Let Down.mp3'
        },
        {
            name:'Nothing Compares To You',
            intro:'Santa Cruz',
            url:'./music/Santa Cruz - Nothing Compares To You.mp3'
        },
        {
            name:'Changing Wind',
            intro:'Shook',
            url:'./music/Shook - Changing Wind.mp3'
        },
        {
            name:'Cloud Symphony',
            intro:'Shook',
            url:'./music/Shook - Cloud Symphony.mp3'
        },
        {
            name:'Strawberry Letter 23',
            intro:'Shuggie Otis',
            url:'./music/Shuggie Otis - Strawberry Letter 23.mp3'
        },
        {
            name:'Dreams',
            intro:'The Cranberries',
            url:'./music/The Cranberries - Dreams.mp3'
        },
        {
            name:'Fools Gold',
            intro:'The Stone Roses',
            url:'./music/The Stone Roses - Fools Gold.mp3'
        },
        {
            name:'Pale Blue Eyes',
            intro:'The Velvet Underground',
            url:'./music/The Velvet Underground - Pale Blue Eyes.mp3'
        },
        {
            name:'Hearing Damage',
            intro:'Thom Yorke',
            url:'./music/Thom Yorke - Hearing Damage.mp3'
        },
        {
            name:'Echoes',
            intro:'Timecop1983',
            url:'./music/Timecop1983 - Echoes.mp3'
        },
        {
            name:'Inspector Norse',
            intro:'Todd Terje',
            url:'./music/Todd Terje - Inspector Norse.mp3'
        },
        {
            name:'90210',
            intro:'Travis Scott',
            url:'./music/Travis Scott - 90210.mp3'
        },
        {
            name:'Born Slippy (NUXX)',
            intro:'Underworld',
            url:'./music/Underworld - Born Slippy (NUXX).mp3'
        },
        {
            name:'ロタティオン (LOTUS-2)',
            intro:'平沢進',
            url:'./music/ロタティオン (LOTUS-2).mp3'
        }
    ]
    const [isInit, setInitStatus] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [isPlay, setPlayStatus] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [current, setCurrent] = useState(list[0]);

    
    const play = () => {
        setInitStatus(false);
        const audio = document.getElementsByClassName(`audio`)[0]
        if(isPlay){
            setPlayStatus(false);
            audio.pause();
        }else{
            setPlayStatus(true);
            audio.play();
        }
    }
    const prev = () => {
        const audio = document.getElementsByClassName(`audio`)[0]
        let index = currentIndex;
        if(currentIndex==0){
            index = list.length-1
        }else{
            index = currentIndex-1
        }
        setCurrentIndex(index)
        setCurrent(list[index])
        audio.src=list[index]['url']
        if(isPlay){
            audio.play();
        }
    }
    const next = () => {
        const audio = document.getElementsByClassName(`audio`)[0]
        let index = currentIndex;
        if(currentIndex==list.length-1){
            index = 0
        }else{
            index = currentIndex+1
        }
        setCurrentIndex(index)
        setCurrent(list[index])
        audio.src=list[index]['url']
        if(isPlay){
            audio.play();
        }
    }

    useEffect(() => {
        const audio = document.getElementsByClassName(`audio`)[0]
        const randomNum = Math.floor(Math.random() * list.length)
        setCurrentIndex(randomNum)
        setCurrent(list[randomNum])
        audio.src=list[randomNum]['url']
    
    }, []);

    const Modalbutton = () => {
        if(isInit && !showModal){
            return <div className={"button init"} onClick={async (e) => { setShowModal(true); play();}}>
                <img className={"init-play"} src={playbtn} />
            </div>
        }else{
            if(showModal){
                return <div className={"button"} onClick={async (e) => { setShowModal(false); }}>-</div>
            }else{
                return <div className={"button"} onClick={async (e) => { setShowModal(true); }}>+</div>
            }
        }
    }

    return (
        <div className={"music-box"}>
            <Modalbutton/>
            <img className={"icon"} src={icon} />
            <audio className={"audio"} src="" onEnded={async (e) => { next(); }}></audio>
            <div  className={["music",showModal?'open':'hide'].join(' ')}>
                <div className={["playingicon",isPlay?'':'static'].join(' ')}>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
                <div className={"info"}>
                    <div className={"name"}>{current['name']}</div>
                    <div className={"intro"}>{current['intro']}</div>
                </div>

                <div className={"playicon"}>
                    <div className={"prev"} onClick={async (e) => { prev(); }}></div>
                    <div className={["play",isPlay?'playing':''].join(' ')} onClick={async (e) => { play(); }}></div>
                    <div className={"next"} onClick={async (e) => { next(); }}></div>
                </div>
            </div>
        </div>
    );
}

export default Music;
